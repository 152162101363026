var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "schema-form-field-label" }, [
    _vm.label
      ? _c(
          "label",
          {
            staticClass:
              "label-lg d-flex flex-row flex-nowrap align-items-center",
          },
          [
            _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
            _vm.helpText
              ? _c("fa-icon", {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.hover.top",
                      value: _vm.helpText,
                      expression: "helpText",
                      modifiers: { hover: true, top: true },
                    },
                  ],
                  staticClass: "question-circle pl-1 help-text",
                  attrs: { icon: "question-circle" },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
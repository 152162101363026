<template>
  <div class="schema-form-field-label">
    <label v-if="label" class="label-lg d-flex flex-row flex-nowrap align-items-center">
      {{ label }}
      <fa-icon
        v-if="helpText"
        v-b-popover.hover.top="helpText"
        class="question-circle pl-1 help-text"
        icon="question-circle"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: 'SchemaFormFieldLabel',
  props: {
    label: {
      type: String,
      default: '',
    },
    helpText: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="scss">

.schema-form-field-label {
  display: block;
}

.question-circle {
  width: 1.25em;
  height: 1.25em;
  color: $ct-ui-primary !important;
}
</style>
